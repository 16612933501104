import { FC, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import { Avatar, Button, Col, Divider, Flex, Layout, Row, Typography } from "antd"

import { ItemType } from "antd/es/menu/interface"
import { useLayoutQuery } from "../../../graphql"
import { Logo } from "../../logo"
import Navigation from "../../menu/Navigation"
import { SidebarLink } from "./SidebarLink"
import { useGeneralContext } from "../../../context/GeneralContext"
import { MenuOutlined } from "@ant-design/icons"
import "./index.less"
import placeholder from "../../../assets/images/placeholder.jpg"

const { Sider } = Layout

interface ISideMenuProps {
  onCloseMenu: () => void,
}

const SideMenu = (props: ISideMenuProps) => {

  const { onCloseMenu } = props
  const { isSalesPerson, isAdmin, profile } = useGeneralContext()
  const { pathname } = useLocation()
  const [menuItems, setMenuItems] = useState<ItemType[] | undefined>(undefined)
  const { data, loading } = useLayoutQuery()

  useEffect(() => {
    const menuFromStrapi = data?.layout?.data?.attributes?.headerMenu?.map(it => {
      return {
        key: it?.url,
        label: <SidebarLink title={it?.title as string} imgURL={it?.icon?.data?.attributes?.url as string}
                            href={it?.url as string} />,
      }
    })
    const menuFromStrapiWithoutLocations = data?.layout?.data?.attributes?.headerMenu?.filter(item => item?.title !== "Locations" && item?.title !== "Profit")?.map(it => {
      return {
        key: it?.url,
        label: <SidebarLink title={it?.title as string} imgURL={it?.icon?.data?.attributes?.url as string}
                            href={it?.url as string} />,
      }
    })
    const adminMenu = data?.layout?.data?.attributes?.adminMenu?.map(it => {
      return {
        key: it?.url,
        label: <SidebarLink title={it?.title as string} imgURL={it?.icon?.data?.attributes?.url as string}
                            href={it?.url as string} />,
      }
    })
    setMenuItems(
      // @ts-ignore
      isAdmin ? adminMenu : isSalesPerson ? menuFromStrapiWithoutLocations : menuFromStrapi,
    )
  }, [loading])

  return (
    <>
      <Sider
        width={200}
        theme={"light"}
        className={"sider"}
      >
        <Row justify={'space-between'}>
          <Link to={"/"} className={"with-menu"}>
            <Logo />
          </Link>
          <Button type="text" icon={<MenuOutlined />}
                  className={"menu"}
                  onClick={onCloseMenu} />
        </Row>

        <Navigation
          style={{ marginTop: 36, border: "none", flex: 1, display: "flex", flexDirection: "column" }}
          mode={"vertical"}
          activeKey={pathname}
          defaultActiveFirst
          items={menuItems}
          onSelect={() => {
            onCloseMenu()
          }}
        />
        <Col className={"profile-container"}>
          <Divider />
          <Row style={{
            paddingLeft: "16px",
            gap: "16px",
          }}>
            <Avatar size={"large"} src={profile?.photo?.data?.attributes?.url} alt={"avatar"} className={"avatar"} />
            <Flex vertical={true}>
              <Typography.Text className={"name"}>{profile?.username}</Typography.Text>
              <Typography.Text className={"role"}>{profile?.role?.data?.attributes?.name}</Typography.Text>
            </Flex>
          </Row>
        </Col>
      </Sider>
    </>
  )
}

export { SideMenu }
