import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from "react"

interface ResponsiveContextProps {
  children: ReactNode
}

export const ResponsiveContext = createContext<{
  isMobile: boolean,
  isTablet: boolean,
  setIsMobile: Dispatch<SetStateAction<boolean>>,
  setIsTablet: Dispatch<SetStateAction<boolean>>,
}>({
  isMobile: false,
  isTablet: false,
  setIsMobile: () => null,
  setIsTablet: () => null,
})

const ResponsiveContextProvider: FC<ResponsiveContextProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1068);
  const contextValue = {
    isMobile,
    setIsMobile,
    isTablet,
    setIsTablet
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1068);
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <ResponsiveContext.Provider value={contextValue}>{children}</ResponsiveContext.Provider>
}

const useResponsiveContext = () => useContext(ResponsiveContext)

export { ResponsiveContextProvider, useResponsiveContext }
