import { Space } from "antd"
import { FC } from "react"
import './index.less';

import { ReactComponent as LogoImage } from "./Logo.svg"

const Logo: FC = () => {
  return (
    <Space direction={"horizontal"} align={"center"} className={'space'}>
      <LogoImage style={{ height: "auto", width: "100%" }} />
    </Space>
  )
}

export { Logo }
